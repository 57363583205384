export default {
  "startGenerating": "Start Generating",
  "cancel": "Cancel",
  "helpMeWriteIt": "Write:",
  "pleaseSelectLevelOne": "Please select a primary category",
  "feedbackFilteringBar": "Feedback filtering criteria",
  "determine": "Confirm",
  "reportSendingLost": "Report sending failed, please try again later: {val1}",
  "reportSentAs": "Report sent successfully",
  "theReportContentIsNot": "Report content cannot be empty",
  "pleaseEnterRequiredField": "Please enter required fields",
  "pleaseEnterYourEmailAddress": "Please enter the full email address, separate multiple with semicolons ; , up to 30 emails supported",
  "mailRecipient": "Mail recipient (default send to yourself)",
  "pleaseEnterToSend": "Please enter the email title to send",
  "emailTitle": "Email Title",
  "emailPushReport": "Email push report settings",
  "helpMeWriteIt2": "Write",
  "inputTriggers": "Type \"/\" to invoke",
  "emailPush": "Email push",
  "editFeedbackScore": "Edit feedback analysis report",
  "hCent": "<h1 {val1}>【{val2}】-AI Analysis Report✍️</h1><p {val3}>(Statistical Period: {val4} ~ {val5}   Creator: {val6})  </p><a href='{val7}?ref=aiReport' target=\"_blank\">View AI report data source</a> 🙌<br/>",
  "store": "{val1} - AI Analysis Report (Statistical Period: {val2} ~ {val3})✍️",
  "generateReport": "Report",
  "setOtherScreens": "Set other filtering criteria",
  "defaultFilterBar": "Default filtering criteria are the same as the overview homepage",
  "youHaveSelectedAs": "You have selected the following filtering criteria, confirm to generate the analysis report?",
  "typicalUserFeedback": "User feedback extraction",
  "specifyCategoryReverse": "Category feedback analysis",
  "overallFeedbackTrend": "Overall trend",
  "feedbackAnalysisSummary": "Analysis summary",
  "lag": "5. Lagging",
  "whenBindingAnAccount": "4. Unable to receive SMS verification code when binding account. This issue has persisted, suspected account risk.",
  "afterPurchasingAtThatTime": "3. Did not receive badge after purchasing.",
  "itsAllAdvertisements": "2. All ads, even with membership still ads, will not use it again. Very disappointed.",
  "taskLeader": "1. Task opportunity draw card has never occurred before, today while doing tasks, tasks could not be refreshed.",
  "aiAutomatically": "AI extracts ≤20 user feedback.",
  "extractTheMostRange": "Extraction range (up to 2 categories):",
  "feedbackQuantityTo": "Top 5 feedback volume issue distribution: Membership auto-renewal closure issues, system message notifications repeated push, prolonged loading on login homepage, order data download failure, WeChat binding QR code issues.\n                  Feedback volume increase ranking: Help and feedback settings not displayed, homepage refresh multiple times, account exit anomaly.",
  "specifyTheMostSuitableCategory": "Specify category (up to two primary categories):",
  "generatingResults": "Generation result range: Month-on-month trend and changes of user feedback",
  "duplicateAccountLogin": "5. Account duplicate login banned 367 pieces (6.3%) 2277.0%↑",
  "qqLoginIsBlocked": "4. QQ login blocked by system manager 252 pieces (5.9%) 304.0%↑",
  "directWithoutNetwork": "3. Direct white screen with no prompt when offline 121 pieces (2.8%) 350.0%↑",
  "fullScreenClick": "2. Full-screen click on download button unresponsive 130 pieces (3.0%) 398.5%↑",
  "pointsAcquisitionAnd": "1. Points acquisition and redemption issues 144 pieces (3.2%) 400.1%↑",
  "soaringProblem": "【Soaring Issues】",
  "aHistoricalCycle": "Feedback volume trend over time (incl. current)",
  "pleaseEnter": "Enter 5 to 20",
  "generateCycleRange": "Cycle range: Integrated near",
  "generateContentDisplay": "Example",
  "feedbackTime": "Feedback time",
  "reportGenerated": "Report done",
  "typicalUserZhen": "User selection done",
  "classificationFeedback": "Classified feedback analysis done",
  "summaryOfFeedback": "Feedback summary done",
  "feedbackTrend": "Trend summary done",
  "feedbackDataScreening": "Data screening done",
  "reportGenerationInProgress": "Generating report...",
  "typicalUserZhen2": "User selection in progress...",
  "classificationFeedback2": "Analyzing feedback...",
  "summaryOfFeedback2": "Generating summary...",
  "feedbackTrend2": "Summarizing trends...",
  "feedbackDataScreening2": "Screening data...",
  "customInformation": "Custom information",
  "applicationVersion": "Version",
  "feedbackVersionSelection": "Feedback version selection",
  "feedbackSourceSelection": "Feedback source selection",
  "feedbackTypeSelection": "Feedback type selection",
  "allCategories": "All",
  "systemInformation": "System information",
  "pleaseChooseFi": "Please choose {val1}",
  "pleaseEnterFi": "Please enter {val1}",
  "multipleKeywords": "Refer to prompts for multiple keyword searches",
  "multipleKeywords2": "Separate multiple keywords with spaces or commas",
  "feedbackContent": "Feedback content",
  "multipleSeparatedBy": "Separate multiple with a comma",
  "pleaseEnterTheContent": "Please enter content...",
  "picture": "Picture",
  "alignment": "Alignment",
  "millionInvestmentRatio": "Investment ratio",
  "feedbackNumber": "Number of feedback",
  "feedbackQuantity": "Fb volume",
  "timeFormatError": "Time format error",
  "forma": "{val1} (Week {val2})",
  "expandInformation": "Expand information",
  "totalProportion": "Total proportion",
  "doNotDisplayNullValues": "Filter null values",
  "urbanDistributionN": "Top 10 City Distribution",
  "provinceDistributionN": "Top 10 Province",
  "equipmentModelClassification": "Top 10 Device Model",
  "equipmentManufacturersAreDividedInto": "Top 10 Device Manufacturer",
  "feedbackDetails": "Feedback detail analysis",
  "aSource": "Source",
  "feedbackSourcesAreDividedInto": "Feedback source distribution",
  "defaultSource": "Default source",
  "feedbackAnalysisSmall": "Feedback analysis summary",
  "province": "Province",
  "equipmentModel": "Device model",
  "versionInformation": "Version information",
  "feedbackSource": "Feedback source",
  "feedbackContent2": "Feedback content (secondary classification)",
  "strip": " ",
  "dailyFeedbackVolume": "Daily feedback volume highest ({val1})",
  "empty": "<Empty>",
  "topLi": "{val1} proportion ({val2})",
  "unitM": "{val1} total proportion ({val2}%)",
  "focusedOn": "Focused on",
  "itemt": "【{val1}】increase up to {val2}",
  "increaseInPrice": "Increase",
  "individual": "Individual",
  "aProvince": "Province",
  "class": "Class",
  "comparedToThePreviousDayOnAMonthOnMonthBasis": "Compared to the previous day",
  "pleaseChooseFi2": "Please choose {val1}",
  "pleaseEnterFi2": "Please enter {val1}",
  "feedbackType": "Feedback type",
  "intelligentClassification": "Fb. Class.",
  "modificationFailedE": "Modification failed: {val1}",
  "setFeedbackWords": "Feedback field settings successful",
  "curTa": "{val1} trend analysis",
  "curTa2": "{val1} statistics",
  "parameterSettings": "Parameter settings",
  "classification": "Classification",
  "fileN": "{val1} trend statistics.xlsx",
  "date": "Date",
  "noDataAvailableAtTheMoment": "No data available at the moment",
  "getFieldOutput": "Error getting field output",
  "monthOnMonthFluctuations": "Monthly fluctuations",
  "feedbackProportion": "Proportion",
  "fieldValue": "Field value",
  "serialNumber": "No.",
  "intel": "{val1} secondary classification feedback volume",
  "thereAreCurrentlyNoTrendNumbersAvailable": "No trend data available at the moment",
  "intel2": "{val1} feedback trend analysis",
  "startNow": "Start now",
  "proportionOfFeedbackVolume": "Feedback ratio",
  "feedbackPercentage": "Feedback person proportion",
  "descendingOrder": "Order",
  "ascendingOrder": "Asc",
  "feedbackClassificationSystem": "Classification statistics: Primary classification",
  "proportion": "Percent",
  "title": "Title",
  "feedbackTypeClassification": "Feedback type distribution",
  "correspondingToTheSelectedTime": "Change in alarm count from the previous same period corresponding to the selected time range",
  "numberOfAlarms": "Alarm count",
  "convertToOnca": "Convert to on-call",
  "replyQuantityReversed": "(Replied quantity / Total feedback quantity) * 100%",
  "responseRate": "Response rate",
  "replyReceived": "Replied",
  "turnedTapd": "Turned to TAPD",
  "correspondingToTheSelectedTime2": "Daily average number of feedback persons corresponding to the selected time range",
  "dailyAverageNumberOfPeople": "Daily average num",
  "dailyAverageFeedbackVolume": "Daily average feedback volume",
  "correspondingToTheSelectedTime3": "Change in feedback volume from the previous same period corresponding to the selected time range",
  "analysis": "Analysis",
  "totalFeedbackAmount": "Total",
  "inStatisticalAnalysis": "After importing DAU data in the statistical analysis/report configuration module, the million investment ratio values can be updated.",
  "correspondingToTheSelectedTime4": "Change in number of feedback persons from the previous same period corresponding to the selected time range",
  "fileT": "{val1} trend statistics.xlsx",
  "problemDetails": "Problem details",
  "goToUserVoice": "View More",
  "getDetailsLost": "Failed to get details"
}