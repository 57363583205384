export default {
  creator: '创建者',
  pleaseEnterADescription: '请输入描述',
  englishFields: '英文字段',
  name: '名称',
  sourceId: '来源ID',
  sourceAttribute: '来源属性',
  pleaseEnterRequiredField: '请输入必填项!',
  feedbackSource: '反馈来源:',
  importClassification: '导入分类',
  exportClassification: '导出分类',
  takeEffect: '进行生效。',
  updateToTerminal: '“更新到终端”',
  inTheManagementView: '在管理视图对分类操作后，别忘了点击',
  updateToTerminal2: '更新到终端',
  confirmUpdateTo: '确认更新到终端吗?',
  createANewFirstLevelScore: '新建一级分类',
  copyData: '复制数据',
  newSource: '新建来源',
  sourceManagement: '来源管理',
  userView: '用户视图',
  managementView: '管理视图',
  exportFailed: '导出失败:',
  exportSuccessful: '导出成功',
  deleteCategory: '删除分类',
  doYouWantToDeleteThis: '是否删除该分类?',
  optionalCustomization: '可选择自定义字段',
  feedbackFillingPage: '反馈填写页面，用户选择此分类后的额外的必填自定义字段',
  feedbackRequiredField: '反馈必填字段',
  statusIsReturned: '状态为”已回复“',
  doNotMakeAnyChanges: '不做变更',
  automaticReplyLetter: '自动回复状态变更',
  whenConfiguringSelf:
    '当配置了自动回复，用户在这个分类下提交反馈后，会收到这个分类下的自动回复文本。',
  automaticReply: '自动回复',
  pleaseSelectFeedback: '请选择反馈来源',
  pleaseInputProcessing: '请输入处理人,多个用;隔开',
  inTermsOfFeedbackTypes: '在反馈类型中可见',
  invisible: '不可见',
  so: '可见',
  visibleToUsers: '用户可见',
  pleaseEnterThePrompt: '请输入提示（不超过 250 个字）',
  classificationId: '分类ID',
  suggestionsForUsers:
    '建议在用户可见的最末级分类进行配置，配置后，用户在输入框中会看到提示内容。',
  classificationTips: '分类提示',
  tips: '提示：',
  suggestionsForUsers2:
    '建议在用户可见的最初级分类进行配置；配置后，用户在选择分类时，会在分类名下看到描述内容。',
  classificationDescription: '分类描述',
  describe: '描述：',
  classificationAttribute: '分类属性',
  pleaseEnterCorrectly: '请输入正确的处理人，多个用 ; 隔开!',
  groupLocation: '所在分组',
  number: '编号',
  thereIsCurrentlyNoAssociatedF: '暂无关联FQA信息, 点击关联FAQ',
  clickToAdd: '点击添加',
  usedAfterConfiguration:
    '配置后, 在用户选择了对应分类之后, 在反馈页面会显示对应的FAQ',
  guessWhatYouWantToAsk: '猜你想问',
  lenovoFaq: '联想 FAQ',
  confirmToDelete: '确定要删除该条关联吗?',
  searchFaq: '搜索 FAQ',
  youHaveAdded: '您已添加',
  addQuestionsTo: '添加问题至分类：',
  addFaq: '添加 FAQ',
  maximumNumberOfAssociations: '最多关联5个 FAQ，请调整后重试',
  adjustSortingTo: '调整排序成功, 请点击更新到终端',
  atDifferentLevels: '不同层级之间不能拖拽改变顺序',
  iteml: '{val1}子分类',
  pleaseFillInOnTheRightSide: '请在右侧填写分类详细信息',
  pleaseSelectWhatYouNeed: '请选择需要导入到的反馈来源',
  uploadCategoryE: '上传分类Excel数据',
  importFailedToSave: '导入失败, 存在以下错误:',
  theIThCategory: '第{val1}行分类存在没有父分类',
  theIThLineExists: '第{val1}行存在“未分类”, 分类名称不能包含“未分类”',
  unclassified: '未分类',
  uploadFailedE: '上传失败: {val1}',
  faile: '{val1}条数据导入失败, 存在以下错误:',
  count: '{val1}条数据导入成功',
  theUserViewIs: '用户视图为预览用户看到的目录树，不可查看详细',
  thereAreCurrentlyNoClassificationLettersAvailable:
    '暂无分类信息, 请点击分类树查看详细信息',
  classificationManagement: '分类管理',
  helpAndFeedback: '用户分类',
  deleteSourceMissing: '删除来源失败!',
  deleteSource: '删除来源成功!',
  deleteCategoryLost: '删除分类失败!',
  deleteClassificationAs: '删除分类成功!',
  updateToTerminal3: '更新到终端失败!',
  updateToTerminal4: '更新到终端成功!',
  firstLevelClassificationName: '一级分类名',
  relatedFaQs: '关联faq失败!',
  relatedFaQs2: '关联faq成功!',
  newSourceLost: '新建来源失败!',
  busin: '{val1}来源成功!',
  level: '{val1}分类失败!',
  level2: '{val1}分类成功, 请点击更新到终端',
  pleaseEnterANote: '请输入备注',
  remarks: '备注',
  enableRules: '启用规则',
  relationshipBetweenConditions: '条件间关系',
  addTo: '添加',
  lengthRestrictionGrid: '长度限制格式为JSON: {"max":xxx, "min": xxx}',
  pleaseSelectFilter: '请选择筛选条件',
  inMultipleProjects: '在多项目中命名相同的来源里生效',
  applicableAfterOpening:
    '开启后, 适用于所属二级产品下所有项目, 对应项目下同步呈现该规则',
  multiProjectApplication: '多项目应用',
  pleaseEnterInvalid: '请输入无效规则名称',
  pleaseEnterTheRules: '请输入规则名称',
  ruleName: '规则名称',
  invalidRuleCreation: '无效规则创建成功',
  invalidRulesAreMore: '无效规则更新成功',
  newInvalidRule: '新建无效规则',
  newlyBuild: '新建',
  invalidRule: '无效规则',
  or: '或',
  and: '且',
  totalTota: '共 {val1} 条',
  areYouSureYouWantToDelete: '您确定要删除该无效规则吗？(删除后不能恢复)',
  operator: '操作人',
  updateTime: '更新时间',
  enable: '启用',
  yes: '是',
  invalidRuleName: '无效规则名称',
  deleteInvalidRules: '删除无效规则成功',
  statu: '{val1}无效规则成功',
  onTheUserPage: '在用户页面中输入值时的提示文字',
  inputPromptText: '输入提示文字',
  doWeNeedASchool: '是否需要校验',
  canBeReversedOnThePlatform: '可在平台反馈列表中修改',
  canItBeModified: '是否可修改',
  inUserSubmission: '在用户提交反馈页面中是否展示该字段',
  canTheUser: '用户是否可见',
  isItMandatory: '是否必填',
  pleaseEnterSorting: '请输入排序',
  sortNumericalValues: '排序数值',
  sort: '排序',
  theFieldValueIsNot: '字段数值不能为空',
  addOptions: '添加选项',
  fieldValueOptions: '字段值选项',
  maximumNumberOfCharacters: '最大字符数限制',
  pleaseSelectAField: '请选择字段类型',
  fieldType: '字段类型',
  lettersOrNumbers: '字母或数字格式, 用于接口传递参数',
  fieldId: '字段ID',
  whenReportingFeedback: '上报反馈时填写的字段名字',
  userEndOrFlat: '用户端或平台页面中展示的信息（唯一性)',
  fieldName: '字段名称',
  feedbackPage: '反馈信息页面中，按此列表中的排序展示字段内容',
  title: '{val1}字段',
  pleaseCompleteTheForm: '请完善表单填写',
  customFields: '自定义字段修改成功',
  customFields2: '自定义字段添加成功',
  pleaseAddFields: '请添加字段值选项',
  currentNam: '当前{val1}已存在，请重新填写',
  pleaseEnterNa: '请输入{val1}',
  defaultRemarks: '默认备注',
  defaultName: '默认名称',
  newlyAdded: '新增',
  link: '链接',
  jsonWord: 'JSON字符串',
  name2: '人名',
  check: '复选',
  singleChoice: '单选',
  floatingPointNumbers: '浮点型数字',
  integerNumber: '整型数字',
  text: '文本',
  doYouWantToDelete: '是否要删除字段',
  deleteCustom: '删除自定义字段',
  pleaseEnterTheField: '请输入字段名称',
  newFieldsAdded: '新增字段',
  customFields3: '自定义字段删除失败',
  customFields4: '自定义字段删除成功',
  isItMandatory2: '是否必选',
  fieldRemarks: '字段备注',
  maximumNumberOfCharacters2: '最大字符数',
  totalTota2: '共 {val1} 项',
  confirmToDelete2: '确定删除',
  confirmCancellation: '确定取消?',
  confirmRelease: '确定发布?',
  announcementLink: '公告链接',
  announcementContent: '公告内容',
  pleaseSelectAnnouncement: '请选择公告生效时间!',
  entryIntoForceTime: '生效时间',
  pleaseEnterTheAnnouncement: '请输入公告链接!',
  pleaseEnterTheAnnouncement2: '请输入公告内容!',
  editAnnouncement: '编辑公告',
  newAnnouncement: '新建公告',
  announcementServiceReleased: '公告服务出现异常: {val1}',
  announcementDataHasBeenReleased: '公告数据已经太长了请先删除无用的公告数据',
  noNeedForRepair: '没有需求修改的公告!',
  noNeedToDelete: '没有需要删除的公告!',
  announcementHasExceeded: '公告已经超过 {val1} 条不允许再新建公告!',
  announcementServiceDifferences: '公告服务异常: {val1}',
  announcementList: '公告列表',
  announcementServiceDifferences2: '公告服务异常!',
  graphicTutorialAttack: '图文/教程/攻略',
  announcementBoard: '公告栏',
  announcementGraphicAndText: '公告图文',
  createANewImageAndText: '新建图文',
  imageAndTextServicesAreAvailable: '图文服务出现异常: {val1}',
  theGraphicAndTextualDataHasBeenProcessed:
    '图文数据已经太长了请先删除无用的图文数据',
  noNeedForRepair2: '没有需求修改的图文!',
  noNeedToDelete2: '没有需要删除的图文!',
  theGraphicsAndTextHaveExceededTheLimit:
    '图文已经超过 {val1} 个不允许再新建图文!',
  differentGraphicAndTextualServices: '图文服务异常: {val1}',
  differentGraphicAndTextualServices2: '图文服务异常!',
  graphicAndTextualLinks: '图文链接',
  imageAndTextTitle: '图文标题',
  graphicCover: '图文封面',
  pleaseUploadPicturesAndText: '请上传图文封面!',
  pleaseSelectTextAndImage: '请选择图文排序位置!',
  textAndImageSorting: '图文排序',
  pleaseInputTextAndImages: '请输入图文链接!',
  pleaseInputTextAndImages2: '请输入图文标题!',
  uploadCover: '上传封面',
  cannotExceedM: '不能超过 {val1}M.',
  doesNotSupportFi: '不支持 {val1} 类型上传',
  unableToObtainIt: '获取不到上传的图片.',
  editImagesAndText: '编辑图文',
  hotspotSorting: '热点排序',
  groupSorting: '分组排序',
  grouping: '分组',
  answer: '回答',
  newQuestion: '新建问题',
  editQuestionS: '编辑问题({val1})',
  theProblemCannotBe: '问题不得为空或超过200个字符!',
  defaultGrouping: '默认分组',
  nextPicture: '下一张',
  ifYouOnlyWantToStop: '如仅想停止对用户的展示，可以使用“停用”。',
  afterDeletionThereWillBeNo: '删除后将无法恢复，请谨慎操作。',
  deleteFaq: '删除 FAQ',
  strin: '{val1}热点',
  showMore: '显示更多',
  hotspot: '热点',
  stopUsing: '停用',
  copyFailed: '复制失败',
  copySuccessful: '复制成功',
  addQuestions: '添加问题',
  quicklyAddYours: '快添加你的第一个问题吧！',
  noProblemAtTheMoment: '暂无问题',
  page: '页',
  the: '第',
  articleRecord: '条记录',
  common: '共',
  answer2: '答案',
  totalTota3: '共{val1}个问题',
  cancelSelection: '取消选择',
  term: '项',
  downloadTemplate: '下载模板',
  batchImport: '批量导入',
  uploadFailed: '上传失败',
  theIThItemInTheDocument: '文件中第{val1}行数据有误，提示: {val2}',
  statu2: '{val1}失败!',
  statu3: '{val1}成功!',
  thisFaqHasBeenSent: '该faq已关联 "{val1}" 分组 - 请解除关联后重试!',
  isHot: '{val1}热点失败!',
  isHot2: '{val1}热点成功!',
  tipsF: '{val1} Faq 问题成功.',
  tipsF2: '{val1} Faq 问题失败 - 内容重复.',
  tipsF3: '{val1} Faq 问题失败 - 接口请求发生错误.',
  groupIcon: '分组图标',
  groupName: '分组名',
  newGroup: '新建分组',
  editGroup: '编辑分组',
  defaultGrouping2:
    '默认分组内的FAQ不会出现在用户端，除非被设为热点或者转到其他分组。',
  deleteGroup: '删除分组',
  youCanAddressTheIssue: '可以在问题内容管理中将问题设为热点问题哦！',
  noGroupingAvailableAtTheMoment: '暂无分组',
  deleteSuccessfully: '删除成功',
  group: '{val1}分组信息成功!',
  group2: '{val1}分组信息失败!',
  aHotTopicIssue: '个热点问题',
  share: '共有',
  cancelHotspotLoss: '取消热点失败!',
  cancelHotspot: '取消热点成功!',
  adjustHotspotF: '调整热点FAQ顺序失败!',
  adjustHotspotF2: '调整热点FAQ顺序成功!',
  cancelHotspot2: '取消热点',
  returnToGroup: '返回分组',
  aQuestion: '个问题',
  importProblem: '导入问题',
  title2: '{val1}FAQ成功',
  title3: '{val1}FAQ失败,内容重复',
  title4: '{val1}FAQ失败,接口请求发生错误',
  removeTheProblem: '移出问题成功',
  removeFromGroup: '移出分组',
  moreOperationNotes: '更多操作记录',
  operationHistory: '操作历史',
  hotContentManagement: '热点内容管理',
  groupManagement: '分组管理',
  problemContentManagement: '问题内容管理',
  faqManagement: 'FAQ管理',
  enabled: '已启用',
  modelInf: '模型: {val1}',
  newModel: '新建模型',
  copyModel: '复制模型',
  viewModel: '查看模型',
  changeTime: '修改时间',
  modelDescription: '模型说明',
  current: '当前',
  modelName: '模型名称',
  totalTota4: '共 {val1} 条',
  modelReplicationLost: '模型复制失败:{val1}',
  copyTheModelInto: '模型复制成功',
  modelCopyingInProgress: '模型复制中，请稍等',
  doYouWantToCopyIt: '是否复制所选模型？',
  exportModelLost: '导出模型失败:{val1}',
  modelDownloadLocation: '模型下载地址为空',
  modelDeletionLost: '模型删除失败:{val1}',
  modelDeletedAs: '模型删除成功',
  doYouWantToDeleteIt: '是否删除所选模型？',
  startTheModel: '启动该模型失败:{val1}',
  enableThisModel: '启用该模型失败',
  successfullyEnabledThis: '成功启用该模型',
  areYouSureToStart: '是否确定启用当前所选模型？',
  modificationFailedPlease: '修改失败: 请确认修改信息后重试!',
  theParentNodeCannot: '父节点无法移动至子节点,当前分类已重置',
  sameNameExists: '存在相同名字的分类,当前分类已重置',
  addIte: '新增{val1}子分类',
  addAFirstLevelScore: '新增一级分类',
  addSel: '新增{val1}子分类',
  pleaseReferToTheTableBelow: '请在下方表单中填写相关信息并保存完成分类添加',
  pleaseUploadTheFile: '请上传文件并生成模型',
  displayDisabled: '显示禁用',
  draggingSucceeded: '拖拽成功更新优先级',
  hideDisabledPoints: '隐藏禁用分类',
  displayDisabledPoints: '展示禁用分类',
  aisee: 'Aisee小助手',
  needToActivateSelf: '需要开通自动回复, 请联系',
  whenConfiguringSelf2:
    '当配置了自动回复内容,智能分类匹配到此分类后,会收到这个分类下的自动回复文本。',
  automaticReplyConfiguration: '自动回复配置',
  existsUnderTheCategory: '分类下存在子分类，请确认是否继续删除？',
  areYouSureToDelete: '是否确认删除？',
  historicalData: '历史数据：',
  priority: '优先级：',
  senior: '高级',
  batchAdd: '批量新增',
  addBlockingWords: '新增屏蔽词',
  thereAreCurrentlyNoBlockedWordsAvailable: '暂无屏蔽词',
  blockWords: '屏蔽词：',
  ifSelectedAfterConfiguration:
    '配置后，若选中的自定义字段有值, 则命中该智能分类',
  customFields5: '自定义字段：',
  addErrorCode: '新增错误码',
  errorCode: '错误码',
  thereAreCurrentlyNoErrorCodesAvailable: '暂无错误码',
  errorCode2: '错误码：',
  learnNewWords: '学习新词',
  addNewWordFrequency: '新增词频',
  wordFrequency: '词频',
  noWordFrequencyAvailableAtTheMoment: '暂无词频',
  wordFrequency2: '词频：',
  addImageClose: '新增图片关键词',
  noPicturesAvailableAtTheMoment: '暂无图片关键词',
  imageKeywords: '图片关键词：',
  addNewKeywords: '新增关键词',
  noKeywordsAvailableAtTheMoment: '暂无关键词',
  keyWord: '关键词：',
  businessScenarios: '业务场景',
  afterConfigurationTheCommandWillBeActivated:
    '配置后，当命中该分类时，将反馈类型修改成所选的值',
  feedbackType: '反馈类型：',
  pleaseFillInTheTitle: '请填写标题',
  title5: '标题：',
  basicConfiguration: '基础配置',
  batchAddM: '批量新增{val1}',
  successfullyUpdatedWord: '成功更新词频',
  updateWordFrequency: '更新词频中',
  categoryDeletedAs: '分类删除成功',
  categoryAddedAs: '分类添加成功',
  updateFailedPlease: '更新失败: 请检查标题是否重复!',
  updateSuccessful: '更新成功',
  imageKeywords2: '图片关键词',
  blockWords2: '屏蔽词',
  reasoningProcess: '推理过程：',
  predictiveClassification: '预测分类：',
  pleaseEnterText: '请输入文本',
  enterText: '输入文本：',
  saveModel: '保存模型',
  uploadGeneratedModel: '上传生成模型',
  unsupervisedDownload: '下载无监督模板',
  downloadWithSupervision: '下载有监督模板',
  pleaseEnterTheModel: '请输入模型说明',
  thereIsCurrentlyNoModelAvailable: '暂无模型说明',
  modelDescription2: '模型说明：',
  pleaseEnterTheModel2: '请输入模型名称',
  modelName2: '模型名称：',
  model: '模型',
  intelligentClassification: '智能分类-',
  uploadFormatIsIncorrect: '上传格式不正确，请上传xls或者xlsx格式',
  noPredictionsAvailableAtTheMoment: '暂无预测数据',
  successfulPrediction: '成功预测',
  modelUpdatedTo: '模型更新成功',
  modelCreation: '模型创建成功',
  pleaseFillInTheModel: '请填写模型名称',
  usedForIntelligentClassification: '智能分类（用/分隔，如增长/提现）',
  pleaseEnterCo: '请输入{val1}，多个使用逗号或空格分割',
  noResultsAvailablePlease: '暂无结果，请先输入反馈ID进行检测',
  detectionResult: '检测结果',
  reset: '重置',
  pleaseEnterTheReverseFirst: '请先输入反馈ID',
  feedbackId: '反馈ID',
  testing: '检测',
  checkFailed: '检查失败',
  pleaseProvideFeedback: '请输入反馈ID',
  addPlugin: '新增插件',
  noteListOnTheLeft: '备注：列表左侧拖拽按钮可以调整触发顺序',
  historicalTriggerRecord: '历史触发记录',
  manuallyTriggeredPress: '人工触发按照启用顺序在反馈详情页排序',
  manuallyTriggered: '人工触发',
  automaticTriggerPress: '自动触发按照启用顺序执行',
  automaticTriggering: '自动触发',
  processingPlugins: '处理插件',
  pluginManagement: '插件管理',
  pleaseEnterAResponse: '请输入响应结构路径',
  responseStructurePath: '响应结构路径',
  requestHeader: '请求头',
  parameter: '参数',
  interfaceTimeoutInSeconds: '接口超时(秒)',
  pleaseEnterAp: '请输入API',
  pleaseSelectTrigger: '请选择触发方式',
  triggerMethod: '触发方式',
  pluginUsage: '插件用途',
  pleaseEnterThePlugin: '请输入插件名称',
  pluginName: '插件名',
  editPlugin: '编辑插件',
  pleaseEnterVa: '请输入value{val1}',
  pleaseEnterKe: '请输入key{val1}',
  updatePluginMissing: '更新插件失败: ',
  updatePluginsTo: '更新插件成功',
  newPluginLost: '新建插件失败:',
  createANewPlugin: '新建插件成功',
  pleaseEnterCorrectly2: '请输入正确的API链接',
  pleaseEnterThePlugin2: '请输入插件名',
  field: '字段',
  fieldName2: '字段名',
  triggerOrderDrag: '触发顺序拖拽失败',
  triggerOrderDrag2: '触发顺序拖拽成功',
  startupSuccessful: '启动成功',
  isItEnabled: '是否启用',
  purpose: '用途',
  triggerType: '触发类型：',
  fail: '失败',
  success: '成功',
  executionStatus: '执行状态：',
  pluginName2: '插件名称：',
  feedbackId2: '反馈ID：',
  coCoun: '共 {val1} 条',
  triggerPerson: '触发人',
  triggerTime: '触发时间',
  returnResult: '返回结果',
  passInParameters: '传入参数',
  executionStatus2: '执行状态',
  pluginName3: '插件名称',
  pleaseEnterTheLabel: '请输入标签名或关键词',
  automaticallyLabel: '自动打标签',
  pleaseUseATemplate: '请使用模板进行导入',
  newLabel: '新建标签',
  businessTags: '业务标签',
  focusOnFailure: '关注失败',
  followSuccess: '关注成功',
  modificationFailed: '修改失败',
  theIThItemInTheDocument2: '文件中第{val1}行数据有误，提示: {val2}',
  tagListAcquisition: '标签列表获取失败：{val1}',
  follow: '关注',
  cancelFollow: '取消关注',
  systemLabel: '系统标签',
  priority2: '优先级',
  tagName: '标签名称',
  totalTota5: '共{val1}条',
  pleasePrioritize: '请确定优先级(数值大于等于0)',
  pleaseEnterCorrectly3: '请输入正确的数字形式',
  multipleKeywords: '多个关键词以空格分隔',
  pleaseEnterTheLabel2: '请输入标签名',
  pleaseEnterCorrectly4: '请正确输入标签名',
  tagName2: '标签名',
  deleteThisTag: '该标签删除后，相应反馈将不能通过该标签检索；删除后不可恢复。',
  confirmToDeleteTheLabel: '确定删除标签:',
  intTa: '{val1}标签`)}',
  deleteFailed: '删除失败',
  newCreationFailed: '新建失败',
  newCreatedSuccessfully: '新建成功',
  ifYouWishTo:
    '如您希望在用户提交反馈的页面中，发布一些重要的公告，可以在AiSee中直接设置引用，这将帮您省去一部分开发成本。',
  ifNeededForSome:
    '若需对一些反馈数据打上特定标签，AiSee支持标签的设置与应用。',
  basedOnTheAlreadyGenerated:
    '基于已产生的用户反馈，当您需要联动业务系统中获取更全面的信息时，可通过插件实现。',
  ifNecessary:
    '如果需要将某些无效的反馈数据过滤掉，您可以根据自己的需要设置一些规则，包含且不限于特定的字段值、正则表达式等。',
  theUserIsSubmitting:
    '用户在提交反馈时，如果希望他们选择问题类别，AiSee可以帮助您预设相应的分类。',
  inUserFeedback:
    '在用户反馈上报的系统信息之外，若您还需要获知更多的内容或特定业务信息，请在自定义信息中设置您的专属字段。',
  youCanTarget:
    '您可以针对某些常见的问题设置FAQ，用于在对应分类下自动回复用户，或支持运营团队选择合适的内容手动回复用户。',
  youCanBaseItOn:
    '您可以根据业务设置自己的分类集，当用户反馈产生时，平台帮助您实现自动分类。',
  replyStatus: '回复状态',
  clickToExpandEmptySpace: '点击展开空值字段',
  clickToCollapse: '点击收起',
  operationHistoryRecord: '操作历史记录',
  systemFields: '系统字段',
  openAiDay: '开通AI日志分析',
  clickToViewTheMost: '点击查看最佳实践',
  byAnalyzingAndUsing:
    '遇到问题不用急，DeepSeek来帮你！轻点「一键分析」，秒级定位异常根因。',
  logAnalysis: '日志分析',
  unbindTap: '解绑Tapd',
  copyLinkSplit: '复制链接分享',
  screenshot: '屏幕截图(',
  feedbackInThePastYear: '近一年反馈',
  to: '于',
  feedbackContentT: '反馈内容: {val1}\n{val2}',
  releaseTap: '解除TAPD绑定成功!',
  opera: '{val1}失败',
  opera2: '{val1}成功',
  logInformation: '日志信息',
  issue: 'issueId为空',
  noChangeHistoryAvailableAtTheMoment: '暂无变更历史',
  remarks2: '备注:',
  informationEditing: '信息编辑',
  processingRecords: '处理记录',
  city: '城市',
  country: '国家',
  network: '网络',
  userIp: '用户IP',
  isItRoo: '是否Root',
  systemVersion: '系统版本',
  model2: '型号',
  manufacturer: '厂商',
  equipmentAndRegion: '设备与地域',
  userTags: '用户标签',
  clickToGoToEach: '点击后向每个处理人发送1条企业微信提醒消息',
  clickToSend: '点击发送通知',
  businessInformation: '业务信息',
  sourceLink: '来源链接',
  receivingTime: '接收时间',
  chooseManualTouch: '选择人工触发的插件手动执行，字段更新结果在变更历史中记录',
  executePlugin: '执行插件',
  essentialInformation: '基本信息',
  notifyRec: '通知: {val1} 出现错误请重试',
  notifyRe: '已通知: {val1}',
  pleaseAddProcessing: '请添加处理人再发送通知',
  enterCategoryName: '输入分类名称搜索',
  searchFeedbackWithin: '搜索反馈内容',
  itemt: '{val1}(无分类)',
  content: '内容',
  whenThePlatformReceives: '接收时间',
  pleaseChooseFi: '请选择{val1}',
  pleaseEnterFi: '请输入{val1}',
  tapdFinished: 'TAPD完结',
  transferToTapd: '转TAPD',
  oncal: 'Oncall结单',
  feedbackProcessingRecord: '反馈处理记录(',
  contactInformation: '联系方式',
  feedbackClassification: '反馈分类:',
  noPermissionToRepairTemporarily: '暂无权限修改分类',
  transferredToPar: '已转{val1}',
  feedbackProcessingStatus: '反馈处理状态',
  whenSubmittingFeedback: '反馈提交时间',
  clickToViewForUse: '点击查看用户【{val1}】近1年反馈历史',
  modifyIntelligentScore: '修改智能分类成功!',
  opera3: '{val1}失败',
  opera4: '{val1}成功',
  exportAllNumbers: '导出全部数据',
  feedbackInput: '反馈录入',
  moreOperations: '更多操作',
  batchEditing: '批量编辑',
  batchReply: '批量回复',
  batchAiPush: '批量AI推荐回复',
  batchExc: '批量Excel导入',
  singleFeedbackRecord: '单条反馈录入',
  batchCancelClose: '批量取消关注',
  batchFollow: '批量关注',
  batchDownload: '批量下载',
  opera5: '{val1}失败',
  opera6: '{val1}成功',
  clickOnDescendingOrder: '点击降序',
  cancelSorting: '取消排序',
  clickOnAscendingOrder: '点击升序',
  pleaseSelectAttributes: '请选择属性标签',
  attributeTags: '属性标签',
  ifThereIsNoneAfterInput: '输入后若无对应标签,按enter键可新增',
  pleaseSelectFeedback2: '请选择反馈类型',
  pleaseSelectAUser: '请选择用户分类',
  informationHasBeenEntered:
    '已输入信息覆盖对应当前的反馈内容，未输入的信息保留原值。',
  additionFailed: '新增失败',
  newSuccessfullyAdded: '新增成功',
  customFields6: '自定义字段编辑',
  systemFieldCoding: '系统字段编辑',
  pleaseSelectBusiness: '请选择业务场景',
  afterSubmittingFeedback: '反馈提交后，向所有处理人自动推送 1 条企微通知',
  searchForMembers: '搜索成员昵称并选择',
  upToFiveUploadsAllowed: '最多上传五张图片，图片最大 10M',
  feedbackAttachment: '反馈附件',
  userFeedback: '用户反馈的原始链接，新增后可在反馈列表中点击跳转',
  pleaseEnterTheLink: '请输入链接',
  whenReceivingUserFeedback: '用户反馈时间',
  pleaseSelectFeedback3: '请选择反馈类型!',
  pleaseProvideFeedback2: '请输入反馈来源!',
  pleaseEnterTheUser: '请输入用户ID或账号',
  theInputContentIsNot: '输入内容不能超过200个字符',
  pleaseEnterTheUser2: '请输入用户信息(用户ID或账号)!',
  pleaseEnterTheUser3: '请输入用户反馈内容',
  pleaseEnterTheUser4: '请输入用户反馈内容!',
  reasonForFailure: '失败原因：',
  singleMaximumLead: '单次最多导入 2,000 条数据',
  clickToUploadE: '点击上传 Excel 文件',
  clickToDelete: '点击删除',
  pleaseProvideFeedback3: '请输入反馈数据!',
  downloadImportModel: '下载导入模版',
  https:
    'https://mf-dcl-1258344701.cos.ap-guangzhou.myqcloud.com/反馈信息批量导入模板-AiSee-v2.xlsx',
  uploadFeedbackCount: '上传反馈数据',
  userFeedbackRecord: '用户反馈录入',
  usedForBatchImport: '批量导入用户反馈',
  singleMaximumLead2: '单次最多导入2000条数据',
  feedbackOnLineI: '第{val1}行，反馈类型为空',
  feedbackOnLineI2: '第{val1}行, 反馈用户为空',
  feedbackOnLineI3: '第{val1}行, 反馈内容为空',
  tableDataError: '表格数据错误，请上传正确的数据',
  inputFailedE: '录入失败：{val1}',
  inputSuccessful: '录入成功',
  importFailedE: '导入失败：{val1}',
  importSuccessful: '导入成功',
  pleaseUploadTheDocumentFirst: '请先上传文件',
  timeSpanIsNot: '时间跨度不能超过一年请重新选择!',
  intelligentProcessing: '智能处理',
  userInformation: '用户信息',
  pleaseSelectATime: '请选择时间',
  search: '搜索',
  itemt2: '{val1}(无分类)',
  optionalRangeOnly: '可选范围仅限当前所在二级产品下的应用',
  belongingToLevel3: '所属三级应用',
  pleaseChooseFi2: '请选择{val1}',
  pleaseEnterFi2: '请输入{val1}',
  transferredToPar2: '已转{val1}',
  currentProduct: '当前产品',
  batchModificationReverse: '批量修改反馈类型',
  allApplications: '全部应用',
  personalCenter: '个人中心',
  intMine: '{val1}',
  intMine2: '{val1}',
  pleaseProvideFeedback4: '请输入反馈内容',
  viewMore: '查看更多',
  theLatestFourQuestions: '最新4条问题',
  waitingForMyApprovalAuthority: '待我审批权限',
  recentlyFollowed: '近1年关注的反馈',
  inRecentYearsItIsExpectedToHappenAgain: '近1年待再次回复',
  recentlyReplied: '近7天已回复',
  followUpInRecentDays: '近30天跟进中问题',
  processedInRecentDays: '近7天已处理问题',
};
