export default {
  "disposalFeedbackLetter": "Feedback Disposal",
  "batchRemovalQuestion": "Batch Removal of Issues",
  "removalSuccessful": "Removal Successful",
  "checkAtLeastOne": "Select at least one feedback",
  "feedbackPercentage": "Feedback Percentage:",
  "feedbackQuantityAsAPercentage": "Feedback Quantity Percentage:",
  "statisticalTimeWeek": "Statistical Period:",
  "alarmRuleCompilation": "Alarm Rule ID",
  "alarmTime": "Alarm Time",
  "alarmName": "Alarm Name",
  "start": "({val1} to {val2})",
  "daysDay": "{val1} days {val2} hours {val3} minutes",
  "dataAcquisitionLost": "Data Acquisition Failed",
  "alarmDetails": "Alarm Details",
  "alarmMonitoring": "Alarm Monitoring",
  "alarmRulesDetailed": "Alarm Rule Details",
  "allStatuses": "All Statuses",
  "statuses": "Statuses",
  "warningRuleName": "Warning Rule Name",
  "warningRuleName2": "Warning Rule Name",
  "subscriobeRuleName": "Subscriobe Rule Name",
  "subscriobeRuleName2": "Subscriobe Rule Name",
  "youCanConfigure": "You can configure rules to detect feedback fluctuations under specific conditions, notifying relevant personnel and appearing in the [Issue Follow-up] list. AiSee assistant has default monitoring rules enabled.",
  "people": "People",
  "notifyPersonnel": "Notify Personnel",
  "notifyTo": "notify to",
  "yearOnYear": "Year-on-Year",
  "heavenlyFluctuations": "Daily Fluctuations >=",
  "recentMonthOnMonthComparison": "Recent Month-on-Month Comparison",
  "allFeedbackSystem": "Total Feedback Statistics",
  "goWithUserId": "User IDs deduplicated,",
  "articleFilteringRules": "Filtering Rules;",
  "hitIt": "Hit",
  "meetTheConditions": "Conditions Met",
  "feedbackFrom": "Feedback from",
  "monitor": "Monitor",
  "fixedFrequency": "Fixed Frequency",
  "frequencyAndScope": "Frequency and Scope",
  "monitoringObject": "Monitoring Target",
  "itemcCreate": "{val1} created on {val2}",
  "itemcUpdate": "Last updated {val1}",
  "disabled": "Disabled",
  "opened": "Enabled",
  "disabledSuccessfully": "Disabled Successfully",
  "successfullyOpened": "Enabled Successfully",
  "proportionOfNewlyAddedFeedback": "Proportion of New Feedback",
  "newFeedbackCount": "New Feedback Count",
  "noMoreNumbers": "No More Data",
  "alarmMonitoringRegulations": "{val1} Alarm Monitoring Rules",
  "subscribeRegulations": "{val1} Subscribe Rules",
  "notifyGroups": "{val1} Notify Groups",
  "fbSubscribe": "Fb Subscrib",
  "newAlarmMonitoring": "Create New Alarm Monitoring Rule",
  "newSubscribeRule": "Create New Subscribe Rule",
  "newNotifyGroup": "Create New Notify Group",
  "editNotifyGroup": "Edit Notify Group",
  "pleaseEnterExtension": "Please enter extension info",
  "afterTheAlarmIsTriggered": "notifications will be sent to all groups containing this bot.",
  "pleaseEnterTheGroupMachine": "Please enter the group bot webhook address",
  "enterpriseWeChatMachine": "Enterprise WeChat Bot",
  "pleaseEnterTheAlarm": "Please enter alarm recipient",
  "recipient": "Recipient",
  "receivingMethod": "Receiving Method",
  "defaultUseOfO": "Default to oncall platform's duty personnel. If not configured, modify the fields for issue assignment.",
  "oncal": "Oncall Default Duty Personnel",
  "pleaseChooseOn": "Please select oncall subspace",
  "alarmTurnedOn": "Alarm to oncall will first convert to issue, then to oncall",
  "alarmTurnedOn2": "Alarm to oncall",
  "sameTimeYesterday": "Feedback volume at the same time yesterday, fluctuation",
  "sameDayAtTheSameTime": "Average feedback volume at the same time today, fluctuation",
  "near": "Near",
  "monthOnMonth": "Month-on-Month",
  "wave": "Fluctuation",
  "threshold": "Threshold",
  "mustAtLeastSelect": "(Must select at least one)",
  "relationshipBetweenConditions": "(Condition Relationship: All conditions must be met)",
  "triggerCondition": "Trigger Condition",
  "applicationVersionTo": "Application Version: Grouped by feedback information's application version field.",
  "regionBasedOnReverse": "Region: City derived from feedback IP field, grouped by city.",
  "highFrequencyKeywords": "High-Frequency Keywords: Extract frequently occurring keywords within the inspection period and aggregate related phrases for alerts.",
  "noStatisticsDirectly": "None: No statistics, directly check if the feedback count meets the 'Threshold' and 'Fluctuation' settings.",
  "dimension": "Dimension",
  "thisInspectionPeriod": "In this inspection period, all feedback from the same user counts as 1",
  "goWithUserId2": "User ID deduplication",
  "statisticalDimension": "Statistical Dimension",
  "relationshipBetweenConditions2": "Condition Relationship: ",
  "filterCriteria": "Filter Criteria",
  "theKeyIssueWithin": "Within, new feedback count under this key event",
  "lately": "Recently",
  "testingPeriod": "Testing Period",
  "detectionFrequency": "Detection Frequency",
  "checkTheAlarmTransferOption": "Select 'Alarm to Issue' or 'Alarm to Oncall'. Must select Enterprise WeChat as receiving method.",
  "pleaseChooseIntelligent": "Please select intelligent label",
  "theMinimumValueIs": "Minimum value is 1",
  "pleaseInputTheFluctuation": "Please enter fluctuation increase",
  "pleaseInputTheFluctuation2": "Please enter fluctuation days",
  "pleaseEnterTheThresholdValue": "Please enter threshold value",
  "addAlarmRules": "New Alarm Rule Added Successfully",
  "addAlarmRules2": "Failed to Add New Alarm Rule",
  "pleaseCheckTheTriggerBox": "Please check the trigger conditions",
  "customFields": "Custom field exception, please try again later",
  "addFilterBar": "Add Filter Condition",
  "pleaseSelectKnown": "Please select known item",
  "pleaseFillInTheFields": "Please fill in field values",
  "filterRelationships": "Filter Relationships",
  "pleaseSelectFilter": "Please select filter relationship",
  "feedbackField": "Feedback Field",
  "pleaseProvideFeedback": "Please fill in feedback field",
  "alarmRules": "Alarm Rules",
  "addAlarmRules3": "Add New Alarm Rules",
  "editAlarmRules": "Edit Alarm Rules",
  "addSubscribeRules": "Add Subscribe Rules",
  "editSubscribeRules": "Edit Subscribe Rules",
  "newAlarmRule": "Create New Alarm Rule",
  "generatedProblem": "Problem Generated",
  "forma": "({val1} to {val2})",
  "forma2": "({val1} to {val2})",
  "suspended": "Suspended",
  "negotiatedAndResolved": "Negotiated and Resolved",
  "closed": "Closed",
  "potential": "Potential",
  "transferredToOnc": "Transferred to Oncall",
  "transferredTap": "Transferred to TAPD",
  "intelligentMonitoring": "Intelligent Monitoring",
  "manualConversion": "Manual Conversion",
  "alarmRuleSetting": "Alarm Rule Settings",
  "byCreatingRegulations": "Monitor feedback changes by creating rules, such as fluctuations in feedback volume or surge in specific issues.",
  "fbSubscribeInfo": "If you need to push feedback notifications that meet specific criteria, please set subscription rules",
  "theSystemWillCommunicateWith": "The system will send a WeChat message to the notifier.",
  "selectNotifier": "Select Notifier",
  "pictureAndVideo": "Picture/Video",
  "clickToUpload": "Click to Upload",
  "upToAttachments": "Attachments: Up to five images, max 10MB each",
  "pleaseEnterFollowUpInformation": "Please enter follow-up information description",
  "problemStatus": "Problem Status",
  "newQuestionsAnd": "New Issue Follow-Up Node",
  "networkAbnormalityPlease": "Network abnormality, please try again later: {val1}",
  "addFollowUpSection": "Follow-Up Node Added Successfully",
  "system": "System",
  "tapdSheet": "TAPD Issue Processed",
  "problemTransferToTa": "Problem Transferred to TAPD",
  "oncal2": "Oncall Issue Processed",
  "problemTurnedOn": "Problem Transferred to Oncall",
  "createAProblem": "Create Problem",
  "problemUpdate": "Problem Update",
  "followUpStatusUpdates": "After follow-up status updates, related feedback synced",
  "followUpRecords": "Follow-Up Records (",
  "problemFollowUpRecord": "Problem Follow-Up Record",
  "itemc2": "{val1} Information Modified",
  "hopeToWatchTogether": "Hope to see the feature can include bullet chat. Invite 50 people to receive 1000B. The benefit received can also be shown in my benefits.",
  "mergedItems": "Merged 17 Same Rule Issues",
  "people2": "40 People",
  "strip": "48 Items",
  "failedToClaim": "Claim Failed, No More Available",
  "problemConsolidationRecord": "Problem Consolidation Record",
  "followUpRecords2": "Follow-Up Records",
  "convertToTapd": "Convert to TAPD",
  "openTap": "Open TAPD",
  "updateInformationTo": "Update Information to Oncall",
  "transferToOnca": "Transfer to Oncall",
  "openOnc": "Open Oncall",
  "followUp": " Follow-Up",
  "convertedToOnc": "Converted to ONCALL",
  "transferredToTap": "Transferred to TAPD/ONCALL",
  "sameRuleIssue": "Same Rule Issue",
  "merged": "Merged",
  "hitHighFrequencyLevel": "Hit High-Frequency Keywords",
  "to": "(2023-09-07 10:40:00 to 2023-09-07 11:40:00)",
  "branch": "Branch",
  "statisticalCycle": "Statistical Cycle",
  "start2": "({val1} to {val2})",
  "feedbackInformationSystem": "Feedback Information Statistics",
  "creationTimeF": "Creation Time {val1}",
  "feedbackProcessing": "Feedback Processing",
  "updateOnc": "ONCALL Information Updated Successfully",
  "modifyTheProcessor": "Failed to Modify Processor, Please Try Again Later",
  "modifyTheProcessor2": "Processor Modified Successfully",
  "networkAbnormalityPlease2": "Network Abnormality, Please Try Again!",
  "removeProblem": "Remove Problem",
  "inTheFeedbackList": "View in Feedback List",
  "relation": "Relation",
  "removeAssociationReverse": "Remove Associated Feedback Successfully!",
  "pleaseSelectAtLeast": "Please select at least one feedback",
  "allQuestionsEtc": "All Issue Levels",
  "sourceOfProblem": "Source of Problem",
  "allProcessingParties": "All Processing Methods",
  "problemHandling": "Problem Handling",
  "enterTheQuestionName": "Enter Issue Name",
  "problemName": "Issue Name",
  "creationTimeF2": "Creation Time {val1}",
  "networkAbnormalityPlease3": "Network Abnormality, Please Try Again: {val1}",
  "thisProjectDoesNotHave": "This project is not bound to TAPD",
  "selectedTo": "Filtered {val1} Data {val2}",
  "theCurrentIssueBelongsTo": "(Current Issue Belongs to Alarm: {val1})",
  "replyStatistics": "Reply Statistics",
  "processingStatistics": "Processing Statistics",
  "exportData": "Export Data",
  "organizationalStructureMaintenance": "Organizational Structure Dimension Data",
  "organizationalStructureMaintenance2": "Organizational Structure Dimension Data ({val1}~{val2})",
  "userResponseStatistics2": "User response statistics after customer service replies ({val1}~{val2})",
  "organizationalStructureMaintenance3": "Organizational Structure Dimension Data \\r\\n{val1} \\r\\n{val2}",
  "userResponseStatistics": "User response statistics after customer service replies \\r\\n{val1} \\r\\n{val2}",
  "dataTimeS": "Data Time: {val1} ~ {val2}",
  "operatorSt": "Operator: {val1}",
  "numberOfConversations": "Number of Conversations",
  "automaticReplyVolume": "Automatic Reply Volume",
  "numberOfManualReplies": "Number of Manual Replies",
  "responseVolume": "Response Volume",
  "organizationalStructure": "Organizational Structure",
  "query": "Search",
  "displayAfterActivation": "After activation, details will display",
  "theCurrentProductIsNotAvailable": "Current product has no intelligent classification. Please check system settings to confirm if an intelligent classification model is bound!",
  "intelligentClassificationDimension": "Intelligent Classification Dimension Data",
  "intelligentClassificationDimension2": "Intelligent Classification Dimension Data ({val1}~{val2})",
  "intelligentClassificationDimension3": "Intelligent Classification Dimension Data \\r\\n{val1} \\r\\n{val2}",
  "dataTimeS2": "Data Time: {val1} ~ {val2}",
  "operatorSt2": "Operator: {val1}",
  "automaticReplyRate": "Automatic Reply Rate",
  "manualResponseRate": "Manual Reply Rate",
  "feedbackResponseRate": "Feedback Reply Rate",
  "smartTags": "Smart Tags",
  "automaticReplyVolume2": "Automatic Reply Volume/Feedback Volume",
  "numberOfManualReplies2": "Manual Reply Volume/Feedback Volume",
  "feedbackOnReplyVolume": "Reply Volume/Feedback Volume",
  "dataOverview": "Data Overview",
  "calculationFormulaF": "Calculation Formula: {val1}",
  "userDataSystem": "User Data Statistics",
  "faqStatistics": "FAQ Statistics",
  "total": "Total",
  "intelligentClassificationName": "Intelligent Classification Name",
  "export": "Export",
  "feedbackClassificationSystem": "Feedback Classification Statistics",
  "exportFailedPlease": "Export failed, please check network status!",
  "selectedCategory": "Selected Category:",
  "accordingToAiS": "Based on AiSee's provided statistical charts, you can set up custom analysis reports and schedule regular email notifications.",
  "reportConfiguration": "Report Configuration",
  "statisticsOfVariousApplications": "Statistics of Feedback Distribution and Trend Changes by Application Version",
  "versionTrendSystem": "Version Trend Statistics",
  "forFeedbackPurposes": "Analysis of Feedback User Characteristics, including System Version, Device Type, Region, and Network.",
  "canViewDifferent": "View response situations for different feedback classifications and response volume indicators for each operator.",
  "statisticalFaq": "Statistics of FAQ Likes and Dislikes",
  "selectTheOneYouWantToClose": "Select the custom parameters you are concerned about, to view feedback volume and trend changes for all parameter values.",
  "viewAll": "View total statistics for all feedback classification levels and the trend changes for each classification.",
  "createABlankReport": "Create Blank Report",
  "selectReportTemplate": "Select Report Template",
  "productScreening": "Product Screening",
  "numberOfDataEntries": "Number of Data Entries",
  "customParameters2": "Custom Parameters",
  "statisticalMethod": "Statistical Method",
  "expandSubcategories": "Expand Subcategories",
  "dynamicTime": "Dynamic Time",
  "staticTime": "Static Time",
  "cycleTime": "Time Cycle",
  "theSelectedTimeIs": "Selected Time: {val1} - {val2}",
  "productFullPath": "Product Full Path: {val1}",
  "chartTrendModel": "Chart Trend Template",
  "deleteInvalidReverse": "Delete Invalid Feedback",
  "statisticalDetails": "Statistical Details",
  "afterOpeningItWillDisplay": "After opening, it will show details of leaf nodes",
  "andTheDecrease": "and decrease ≥",
  "feedbackQuantity": "Feedback Quantity ≥",
  "fallingFast": "Rapid Decline",
  "andTheIncrease": "and increase ≥",
  "fastGrowth": "Rapid Growth",
  "allTheSounds": "% of All Voices",
  "sumOfFeedbackQuantities": "Sum of Feedback Quantities ≥",
  "manyFeedbacks": "Many Feedbacks",
  "highlightProblemScreening": "Highlight Problem Screening",
  "userClassification": "User Classification",
  "selectClassificationSystem": "Select Classification Statistics Method",
  "productFullPath2": "Product Full Path: {val1}",
  "theSelectedTimeIs2": "Selected Time: {val1} - {val2}",
  "textTemplate": "Text Template",
  "pleaseEnterTheGroupMachine2": "Please enter group bot webhook address list, separate multiple with semicolons (;)",
  "enterpriseWeChatMachine2": "Enterprise WeChat Bot:",
  "openEnterpriseMicro": "After opening Enterprise WeChat notifications, emails will be sent simultaneously.",
  "enterpriseWeChatNotification": "Enterprise WeChat Notification",
  "sendEmail": "Send Email",
  "manual": "Manual",
  "timing": "Scheduled",
  "sendingFrequency": "Sending Frequency",
  "pleaseEnterEmail": "Please enter email subject",
  "inTheConfigurationReport": "Configure report content",
  "sunday": "Sunday",
  "saturday": "Saturday",
  "friday": "Friday",
  "thursday": "Thursday",
  "wednesday": "Wednesday",
  "tuesday": "Tuesday",
  "monday": "Monday",
  "day": "Day",
  "week": "Week",
  "iScore": "{val1} points",
  "iScore2": "0{val1} points",
  "atITime": "{val1} hours",
  "atITime2": "0{val1} hours",
  "optionalStatisticsGroup": "Optional Statistics Components",
  "responseRateTrend": "Response Rate Trend Chart",
  "parameterTrendChart": "Parameter Trend Chart",
  "parameterMonthOnMonthTrend": "Parameter Month-on-Month Trend Chart",
  "millionInvestmentRatioTrend": "Million Investment Ratio Trend Chart",
  "millionInvestmentRatioTable": "Million Investment Ratio Table",
  "intelligentClassificationAccountsFor": "Intelligent Classification Proportion Distribution Chart",
  "intelligentClassificationAndIntegration": "Overall Trend Chart of Intelligent Classification",
  "intelligentLabelTrend": "Intelligent Label Trend Chart",
  "feedbackQuantityTrend": "Feedback Quantity Trend Chart",
  "feedbackOnMonthOnMonthTrend": "Feedback Month-on-Month Trend Chart",
  "textContent": "Text Content",
  "numberOfClassifiedFeedback": "Classified Feedback Data Table",
  "addComponentsAs": "Components Added Successfully",
  "selectedComponentColumn": "Selected Component List, click 'Edit' to configure data, then click 'Preview Report' to view real data",
  "saveReport": "Save Report",
  "manuallySend": "Manual Sending",
  "previewReport": "Preview Report",
  "return": "Return",
  "previousStep": "Previous Step",
  "createReport": "Create Report",
  "pleaseSelectAtLeast2": "Please select at least one statistical component",
  "pleaseFillInTheEmail": "Please fill in email sending address",
  "pleaseFillInTheEmail2": "Please fill in email",
  "serverException": "Server exception, please try again later!",
  "createdSuccessfully": "Creation successful",
  "manuallySendMail": "Manual email sending failed!",
  "manuallySendingReports": "Manual report sending successful!",
  "serverException2": "Server exception, please contact the administrator",
  "monthI": "{val1} month",
  "excel": "Excel data",
  "thereIsDataAvailable": "Data available",
  "edit": "Edit",
  "confirmCancellationOfEditing": "Are you sure you want to cancel editing?",
  "preservation": "Save",
  "operation": "Operation",
  "dataValue": "Data value",
  "analysisResults": "Analysis results",
  "exhibition": "Display",
  "putItAway": "Collapse",
  "downloadTemplate": "Download template",
  "maximumSingleSupport": "Maximum single import support is 5000 records",
  "supportCommonlyUsedX": "Supports commonly used formats: xlsx/xls/cvg",
  "dragAndDropToThisArea": "Drag and drop to this area",
  "reUpload": "Re-upload",
  "pleaseUploadTheCorrespondingFile": "Please upload the corresponding Excel file",
  "pleaseChooseEx": "Please select the corresponding intelligent classification for Excel",
  "uploadExc": "Upload Excel data",
  "tableDateCount": "Table date data is not standardized, please modify and upload",
  "thereAreBlankLinesPresent": "There are blank lines or formatting errors",
  "tableDataError": "Table data error, please upload correct data",
  "uploadSuccessful": "Upload successful",
  "pleaseUploadTheFile": "Please upload the file",
  "dataDisplay": "Data display",
  "dataUpload": "Data upload",
  "newReport": "Create new report",
  "pleaseEnterTheReport": "Please enter report name",
  "reportName": "Report name",
  "viewReport": "View report",
  "myReport": "My reports",
  "allReports": "All reports",
  "deleteReport": "Delete report",
  "deleteFailed": "Deletion failed!",
  "deleteSuccessfully": "Deletion successful",
  "areYouSureYouWantToDeleteIt": "Are you sure you want to delete {val1}?",
  "copyFailedPlease": "Copy failed, please try again later~",
  "copySuccessful": "Copy successful!",
  "dataExceptionPlease": "Data exception, please contact the administrator",
  "networkAnomalyCutting": "Network anomaly: Failed to switch push switch, please refresh the page and try again!",
  "switchPushOn": "Push switch toggled successfully!",
  "delete": "Delete",
  "confirmToClosePush": "Are you sure to close push notifications?",
  "confirmToStartPushing": "Are you sure to start pushing notifications?",
  "pushSwitch": "Push switch",
  "whenSendingNextTime": "Next sending time",
  "lastTimeItWasSent": "Last sending time",
  "modifiedBy": "Modified by",
  "creator": "Creator",
  "mail": "Email",
  "sendingChannel": "Sending channel:",
  "sentClick": "] Sent, click to view details",
  "historicalRecords": "Historical records",
  "sendingFrequency2": "Sending frequency:",
  "reportTitle": "Report title:",
  "reportDetails": "Report details",
  "obtainExecutionLetter": "Failed to obtain execution information, please try again later",
  "thereAreCurrentlyNoChartNumbersAvailable": "No chart data available, please select filter criteria",
  "intelligentClassificationReverse": "Intelligent classification feedback ratio",
  "feedbackVolumeComparedToThePreviousPeriod": "Feedback volume compared to previous period",
  "dailyAverageOfThePreviousCycle": "Average daily feedback volume of the previous cycle",
  "feedbackFromThePreviousCycle": "Feedback volume from the previous cycle",
  "feedbackFromThePreviousCycle2": "Feedback persons from the previous cycle",
  "feedbackFromThePreviousCycle3": "Feedback volume from the previous cycle",
  "feedbackPerson": "Feedback person",
  "monthOnMonthData": "Month-on-month data",
  "lastCycle": "Previous cycle",
  "thisCycle": "Current cycle",
  "underFilteringConditions": "Filtering conditions: Rapid decline (feedback volume ≥",
  "filterCriteriaIncrease": "Filtering conditions: Rapid growth (feedback volume ≥",
  "allTheSounds2": "% of all voices)",
  "filterConditionReverse": "Filtering conditions: High feedback (total feedback volume ≥",
  "product": "Product:",
  "responseRate": "Response rate",
  "responseQuantity": "Responded quantity",
  "feedbackFromThePreviousPeriod": "Feedback quantity from the previous period",
  "classificationName": "Classification name",
  "feedbackPersonFromThePreviousIssue": "Feedback persons from the previous period",
  "noDataAvailablePlease": "No data available, please select filter criteria",
  "millionInvestmentRatio": "Million investment ratio month-on-month",
  "value": "{val1} times",
  "value2": "{value} times",
  "responseRateStatistics": "Response rate statistics table",
  "iAmATextCharacter": "I am the explanation content of the text field, repeating the explanation content.",
  "textTitle": "Text title",
  "whole": "Overall",
  "dailyAverageOfThePreviousCycle2": "Average daily feedback persons of the previous cycle",
  "dailyFeedbackPerson": "Average daily feedback persons",
  "product2": "Product",
  "editReport": "Edit report",
  "reportPreview": "Report preview",
  "classificationChart": "Classification chart",
  "lastS": "{val1} (Scheduled)",
  "sendingTime": "Sending time:",
  "keyWord": "Keyword",
  "aiSceneMining": "AI scene mining",
  "userVoiceColumn": "User voice list",
  "userVoiceDetails": "User voice details",
  "byHeat": "By Heat",
  "accordingToTheSoaringTrend": "By Trend",
  "pleaseChooseAnAction": "Please select an operator",
  "numberOfFeedbacks": "Number of feedbacks",
  "aiSee": "AiSee assistant summarizes user voices based on feedback content, allowing you to view trends and convert them into issues for follow-up (e.g., sudden feedback spikes, user voices on revisions).",
  "correspondingTimeSheet": "Feedback volume matching this topic within the corresponding time unit / total feedback volume within this time unit * 100%",
  "correspondingTimeSheet2": "Semantic matching of feedback content to this topic within the corresponding time unit: {val1}",
  "typen2": "{val1} trend",
  "articleData": "Records",
  "selected": "Selected",
  "fixed": "fixed",
  "pending": "pending",
  "notReproduce": "notReproduce",
}